<template>
    <div class="creation-content">
        <div class="creation-progress">
            <div class="progress-content">
                <div class="step-num">
                    <span class="active">1</span>
                    <span>创建训练</span>
                </div>
                <img class="step-wire"
                     :src="active > 1 ? require('./../../assets/images/serviceData/blue.png') : require('./../../assets/images/serviceData/xian.png')"
                     alt="">
                <div class="step-num">
                    <span :class="active > 1 ? 'active' : ''">2</span>
                    <span>创建成功</span>
                </div>
            </div>
        </div>
        <el-scrollbar class="creation-form">
            <div class="step-one" v-if="active ==1">
                <el-form :rules="rules" ref="form" :model="form" label-width="130px" label-position="right">
                    <el-form-item label="理论训练名称" prop="train_name">
                        <el-input style="width: 500px;" v-model="form.train_name" maxlength="25" placeholder="请输入训练名称">
                            <span slot="suffix" class="el-input__icon">4 - 25</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="选择理论题库" prop="categoryId">
                        <el-cascader v-model="form.categoryId" :options="theoryList"
                                     :props="{value:'id',label:'name',children:'child'}"
                                     style="width: 500px;">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="startTime">
                        <el-date-picker
                                v-model="form.startTime"
                                @change="selectStartTime"
                                :picker-options="pickerOptions"
                                type="datetime"
                                value-format="timestamp"
                                placeholder="选择开始日期时间"
                                style="width: 500px;">
                        </el-date-picker>
<!--                        <span class="time-tips">（训练将在创建成功后5分钟后自动开启，您可提前手动开始训练，训练结束后无法重新开启））</span>-->
                    </el-form-item>
                    <el-form-item label="结束时间" prop="endTime">
                        <el-date-picker
                                v-model="form.endTime"
                                @change="selectEndTime"
                                :picker-options="pickerOptions"
                                type="datetime"
                                value-format="timestamp"
                                placeholder="选择结束日期时间"
                                style="width: 500px;">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="选择班级" prop="class_id">
                        <el-select v-model="form.class_id" placeholder="请选择班级" style="width: 500px;">
                            <el-option v-for="(item,index) in classList" :key="index" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="form-bottom">
                    <el-button @click="goBack">返回</el-button>
                    <el-button class="blue-button" @click="createTheory('form')" :disabled="createStatus">创建 <i v-if="createStatus" class="el-icon-loading"></i></el-button>
                </div>
            </div>
            <div class="step-two" v-if="active==2">
                <div class="succeed-icon">
                    <i class="iconfont">&#xe730;</i>
                    <span>操作成功!</span>
                </div>
                <div class="getBack">
                    <el-button class="getBackBtn" @click="goBack">返回</el-button>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {theorytrainSave, theorytrainTopic, collegeClass, theorytrainInfo} from '@/utils/apis'

    export default {
        name: "creationTheory",
        data() {
            return {
                activeName: 'raceAdmin',
                active: 1,
                theoryList: [],
                classList: [],
                form: {
                    train_name: '',
                    categoryId: '',
                    startTime: '',
                    endTime: '',
                    class_id: '',
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                    }
                },
                rules: {
                    train_name: [
                        {required: true, message: '请输入理论训练名称', trigger: 'blur'},
                        {min: 4, max: 25, message: '长度在 4 到 25 个字符', trigger: 'blur'}
                    ],
                    categoryId: [
                        {required: true, message: '请选择理论题库', trigger: 'change'}
                    ],
                    startTime: [
                        {type: 'date', required: true, message: '请选择日期', trigger: 'change'}
                    ],
                    endTime: [
                        {type: 'date', required: true, message: '请选择日期', trigger: 'change'}
                    ],
                    class_id: [
                        {required: true, message: '请选择班级', trigger: 'change'}
                    ],
                },
                editId: this.$route.query.id || null,
                theory_category_id: null,
                createStatus: false
            }
        },
        mounted() {
            if (this.editId) {
                this.getSingleInfo()
            }
            this.getTheoryList()
            this.getClassList()
        },
        methods: {
            getSingleInfo() {
                theorytrainInfo({id: this.editId}).then(res => {
                    if (res.code == 200) {
                        this.form.train_name = res.data.train_name
                        this.form.startTime = Date.parse(res.data.start_time)
                        this.form.endTime = Date.parse(res.data.end_time)
                        this.form.class_id = res.data.class_id
                        this.theory_category_id = res.data.theory_category_id
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getParent(id) {
                this.theoryList.forEach(item => {
                    if (item.child) {
                        item.child.forEach(subItem => {
                            if (subItem.id == id) {
                                this.form.categoryId = [item.id, id]
                            }
                        })
                    } else {
                        this.form.categoryId = [id]
                    }
                })
                console.log(this.form.categoryId, 999)
            },
            getTheoryList() {
                theorytrainTopic().then(res => {
                    if (res.code == 200) {
                        this.theoryList = res.data
                        if (this.editId) {
                            this.getParent(this.theory_category_id)
                        }
                    }
                })
            },
            getClassList() {
                collegeClass().then(res => {
                    if (res.code == 200) {
                        this.classList = res.data.data
                    }
                })
            },
            // 选择开始时间
            selectStartTime(val) {
            },
            selectEndTime(val) {
                if (!this.form.startTime) {
                    this.$message.warning('请先选择开始时间！');
                    this.form.endTime = ''
                } else {
                    if (this.form.endTime < this.form.startTime) {
                        this.$message.warning('请选择开始时间之后的日期！');
                        this.form.endTime = ''
                    }
                }
            },
            goBack() {
                this.active = 1
                this.resetAll()
                this.$router.push('/trainAdmin/theoryManage/index')
            },
            resetAll() {
                this.form = {
                    train_name: '',
                    categoryId: '',
                    startTime: '',
                    endTime: '',
                    class_id: '',
                }
            },
            createTheory(formName) {

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.createStatus = true;
                        let params = {
                            train_name: this.form.train_name,
                            class_id: this.form.class_id,
                        }
                        params.start_time = this.formatDate(this.form.startTime)
                        params.end_time = this.formatDate(this.form.endTime)
                        params.theory_category_id = this.form.categoryId[this.form.categoryId.length - 1]
                        if (this.editId) {
                            params.id = this.editId
                        }
                        theorytrainSave(params).then(res => {
                            this.createStatus = false;
                            if (res.code == 200) {
                                this.$message.success(res.msg)
                                this.resetAll()
                                this.active++
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            formatDate(val) {
                let date = new Date(val);
                let YY = date.getFullYear() + '-';
                let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                return YY + MM + DD + " " + hh + mm + ss;
            }
        }
    }
</script>

<style scoped lang="scss">
    .creation-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .creation-progress {
            display: flex;

            .progress-content {
                margin: 64px auto;
                display: flex;
                align-items: center;

                .step-num {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span:nth-child(1) {
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        color: #FFFFFF;
                        background: #DCDCDC;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 48px;
                    }

                    .active {
                        background: #1122D8 !important;
                    }

                    span:nth-child(2) {
                        display: inline-block;
                        width: 100px;
                        text-align: center;
                        top: 68px;
                        /*left: 30%;*/
                        position: absolute;
                        font-size: 16px;
                        color: #333333;

                    }
                }

                .step-wire {
                    height: 14px;
                    margin: 0 10px;
                }
            }
        }

        .creation-form {
            flex: 1;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                height: 100%;

                .el-scrollbar__view {
                    margin: 40px 0 30px 30px;
                    height: calc(100% - 70px);
                }
            }

            .step-one {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .time-tips {
                    margin-left: 10px;
                    color: #999999;
                }

                ::v-deep .el-form-item__label {
                    color: #13131B;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }

                .form-bottom {
                    text-align: center;

                    .blue-button {
                        background: #1122D8;
                        color: #fff;
                    }
                }
            }

            .step-two {
                .succeed-icon {
                    padding-top: 50px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 52px;
                        color: #20D28C;
                    }

                    span {
                        color: #343434;
                        font-size: 24px;
                        font-weight: bold;
                    }
                }

                .getBack {
                    display: flex;
                    justify-content: center;
                    padding-top: 400px;

                    .getBackBtn {
                        width: 100px;
                        height: 30px;
                        background: #ffffff;
                        border-color: #1222D8;
                        color: #1222D8;
                        line-height: 0;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
</style>